import React, { useState } from 'react';

const SalesFilter = ({ 
  filters, 
  setFilters, 
  companies, 
  products, 
  personnelData, 
  onFilter,
  filteredData 
}) => {
  const [isFiltered, setIsFiltered] = useState(false);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFilter = () => {
    onFilter();
    setIsFiltered(true);
  };

  const clearFilters = () => {
    setFilters({
      personnel_id: '',
      company_name: '',
      product_name: '',
      startDate: '',
      endDate: '',
    });
    setIsFiltered(false);
  };

  // Helper functions
  const safeNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? 0 : number;
  };
  
  const formatMoney = (value, isCommission = false) => {
    // Komisyon değeri için 100'e bölmeye gerek yok çünkü TL cinsinden
    const number = isCommission ? safeNumber(value) : safeNumber(value) / 100;
    return number.toLocaleString('tr-TR', { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    }) + ' TL';
  };

  // Verileri ürüne göre gruplama
  const groupByProduct = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.product_name]) {
        acc[item.product_name] = {
          count: 0,
          netPremium: 0,
          grossPremium: 0,
          commission: 0 // Ürün bazında komisyon eklendi
        };
      }
      acc[item.product_name].count++;
      acc[item.product_name].netPremium += safeNumber(item.net_premium);
      acc[item.product_name].grossPremium += safeNumber(item.gross_premium);
      acc[item.product_name].commission += safeNumber(item.commission); // Komisyon toplamı eklendi
      return acc;
    }, {});
  };

  // Verileri personele göre gruplama
  const groupByPersonnel = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.personnel_id]) {
        acc[item.personnel_id] = {
          count: 0,
          netPremium: 0,
          grossPremium: 0,
          commission: 0,
          products: {}
        };
      }
      acc[item.personnel_id].count++;
      acc[item.personnel_id].netPremium += safeNumber(item.net_premium);
      acc[item.personnel_id].grossPremium += safeNumber(item.gross_premium);
      acc[item.personnel_id].commission += safeNumber(item.commission);
  
      if (!acc[item.personnel_id].products[item.product_name]) {
        acc[item.personnel_id].products[item.product_name] = {
          count: 0,
          netPremium: 0,
          grossPremium: 0,
          commission: 0
        };
      }
      acc[item.personnel_id].products[item.product_name].count++;
      acc[item.personnel_id].products[item.product_name].netPremium += safeNumber(item.net_premium);
      acc[item.personnel_id].products[item.product_name].grossPremium += safeNumber(item.gross_premium);
      acc[item.personnel_id].products[item.product_name].commission += safeNumber(item.commission);
      
      return acc;
    }, {});
  };

  return (
    <div className="space-y-6">
      {/* Filtreleme Alanı */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-6 text-indigo-800">Satış Listesi Filtreleme</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Sol Kolon - Şirket, Ürün, Personel */}
          <div className="space-y-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Şirket Seçin</label>
              <select
                name="company_name"
                value={filters.company_name}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Tüm Şirketler</option>
                {companies.map((company) => (
                  <option key={company} value={company}>{company}</option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Ürün Seçin</label>
              <select
                name="product_name"
                value={filters.product_name}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Tüm Ürünler</option>
                {products.map((product) => (
                  <option key={product} value={product}>{product}</option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Personel Seçin</label>
              <select
                name="personnel_id"
                value={filters.personnel_id}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Tüm Personeller</option>
                {Object.entries(personnelData).map(([id, name]) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Sağ Kolon - Tarihler ve Butonlar */}
          <div className="space-y-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Başlangıç Tarihi</label>
              <input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Bitiş Tarihi</label>
              <input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            <div className="flex space-x-4 pt-6">
              <button
                onClick={handleFilter}
                className="flex-1 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors"
              >
                Filtrele
              </button>
              <button
                onClick={clearFilters}
                className="flex-1 bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors"
              >
                Temizle
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Özet Bilgiler */}
      {isFiltered && (
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-indigo-800">Filtreleme Özeti</h2>
          
          {/* Genel Toplam */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <div className="bg-blue-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-blue-800 mb-2">Toplam Poliçe Sayısı</h3>
              <p className="text-3xl font-bold text-blue-900">{filteredData.length}</p>
            </div>

            <div className="bg-purple-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-purple-800 mb-2">Toplam Brüt Prim</h3>
              <p className="text-3xl font-bold text-purple-900">
                {formatMoney(filteredData.reduce((total, row) => total + safeNumber(row.gross_premium), 0))}
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-green-800 mb-2">Toplam Komisyon</h3>
              <p className="text-3xl font-bold text-green-900">
                {formatMoney(filteredData.reduce((total, row) => total + safeNumber(row.commission), 0), true)}
              </p>
            </div>
          </div>

          {/* Ürün Bazlı İstatistikler */}
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-4 text-gray-800">Ürün Bazlı İstatistikler</h3>
            <div className="grid grid-cols-1 gap-4">
              {Object.entries(groupByProduct(filteredData)).map(([product, stats]) => (
                <div key={product} className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">{product}</h4>
                  <div className="grid grid-cols-3 gap-4">
                    <p>Adet: {stats.count}</p>
                    <p>Üretim: {formatMoney(stats.grossPremium)}</p>
                    <p>Komisyon: {formatMoney(stats.commission, true)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Personel Bazlı İstatistikler */}
          <div>
            <h3 className="text-xl font-bold mb-4 text-gray-800">Personel Bazlı İstatistikler</h3>
            <div className="grid grid-cols-1 gap-4">
              {Object.entries(groupByPersonnel(filteredData)).map(([personnelId, stats]) => (
                <div key={personnelId} className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">{personnelData[personnelId]}</h4>
                  <div className="grid grid-cols-3 gap-4 mb-2">
                    <p>Toplam Poliçe: {stats.count}</p>
                    <p>Üretim: {formatMoney(stats.grossPremium)}</p>
                    <p>Komisyon: {formatMoney(stats.commission, true)}</p>
                  </div>
                  <div className="pl-4 border-l-2 border-gray-200">
                    <h5 className="font-medium mb-2">Ürün Dağılımı:</h5>
                    {Object.entries(stats.products).map(([product, productStats]) => (
                      <div key={product} className="grid grid-cols-3 gap-4 text-sm">
                        <p>{product}: {productStats.count} adet</p>
                        <p>Üretim: {formatMoney(productStats.grossPremium)}</p>
                        <p>Komisyon: {formatMoney(productStats.commission, true)}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Filtrelenmiş Sonuçlar Tablosu */}
      {isFiltered && (
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-indigo-800">Filtrelenmiş Sonuçlar</h2>
          
          {/* Aktif Filtreler Özeti */}
          <div className="bg-yellow-50 p-4 rounded-md mb-4">
            <h3 className="font-semibold text-yellow-800 mb-2">Aktif Filtreler:</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm">
              {filters.company_name && (
                <div className="text-yellow-700">Şirket: {filters.company_name}</div>
              )}
              {filters.product_name && (
                <div className="text-yellow-700">Ürün: {filters.product_name}</div>
              )}
              {filters.personnel_id && (
                <div className="text-yellow-700">Personel: {personnelData[filters.personnel_id]}</div>
              )}
              {filters.startDate && (
                <div className="text-yellow-700">Başlangıç: {filters.startDate}</div>
              )}
              {filters.endDate && (
                <div className="text-yellow-700">Bitiş: {filters.endDate}</div>
              )}
            </div>
          </div>

          {/* Sonuçlar Tablosu */}
        {/* Sonuçlar Tablosu */}
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Satış ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Personel
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Şirket
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ürün
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Üretim Türü
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Yeni/Yenileme
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Net Prim
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Brüt Prim
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Komisyon
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredData.map((row) => (
                  <tr key={row.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {personnelData[row.personnel_id]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.company_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.product_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.production_type}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.new_or_renewal}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {formatMoney(row.net_premium)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {formatMoney(row.gross_premium)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {formatMoney(row.commission, true)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesFilter;

